/* eslint-disable import/no-anonymous-default-export */
import React, { lazy, Suspense } from 'react'
import { Route, Switch, Redirect } from "react-router";
import { BrowserRouter } from "react-router-dom";

const Index = lazy(() => import('./pages/App.js'))
const Deposit = lazy(() => import('./pages/deposit'))
export default () => {
  return (
    <BrowserRouter>
      <Switch>
      <Suspense fallback={< ></> }>
        <Route exact path="/" component={Index}/>
        <Route  path="/deposit" component={Deposit} />
        </Suspense>
      </Switch>
    </BrowserRouter>
  );
};
